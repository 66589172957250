<template>
  <section class="taikai">
    <AppHeader :header-text="headerText" />
    <v-content>
      <HomeTitle :title-text="titleText" />
      <TaikaiMain/>
    </v-content>
    
    <AppFooter/>
  </section>
</template>

<script>
import HomeTitle from '@/components/HomeTitle.vue';
import TaikaiMain from '@/components/TaikaiMain.vue';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'Taikai',
  components: {
    HomeTitle,
    TaikaiMain,
    AppHeader,
    AppFooter,
  },

  data: () => ({
    titleText: '退会のお手続き',
    headerText: '退会申請',
  }),

}
</script>

<style lang="scss" scoped>
.taikai {
    padding-bottom: 50px;
}
</style>