<template>
    <v-row class="taikai-checkbox__contents">
        <v-col cols="12" sm="8" md="4" class="taikai-checkbox__contents-text">
            <v-card flat color="transparent">
                <v-card-text>
                    <v-form>
                        <h3>退会理由</h3>

                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-checkbox label="健康上の理由" hide-details></v-checkbox>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                                <v-checkbox label="旅行・帰省" hide-details></v-checkbox>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                                <v-checkbox label="家庭の事情" hide-details></v-checkbox>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                                <v-checkbox label="仕事の都合" hide-details></v-checkbox>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                                <v-checkbox v-model="enabled" label="その他" hide-details></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-text-field class="other" :disabled="!enabled" label="その他 理由を記載ください。" required></v-text-field>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

</template>

<script>
export default {
    
    data: () => ({

        enabled: false,

        reasons: ["健康上の理由","旅行・帰省","家庭の事情","仕事の都合"],
        other: ["その他"],

    }),
}
</script>

<style lang="scss" scoped>
.taikai-checkbox__contents {
    justify-content: center;
    text-align: left;

    &-text {
        word-wrap: break-word;   
    }
}
</style>