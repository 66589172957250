<template>
    <section class="taikai-main">
        <v-container class="fill-height" fluid>
            <v-row class="taikai-main__contents">
                <v-col cols="12" sm="8" md="4" class="taikai-main__contents-text">

                    <v-card flat color="transparent">
                        <v-card-text>
                            <v-form ref="form">
                                <v-text-field class="memberid" v-model="memberid" :rules="memberidRules" prepend-inner-icon="mdi-account-circle" label="会員番号" required @change="getname" clearable @click:clear="onClearName"></v-text-field>
                                
                                <!-- 生年月日 -->
                                <v-menu ref="birthdaymenu" v-model="birthdaymenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field  class="birthday" v-model="birthday" :rules="birthdayRules" label="生年月日" prepend-inner-icon="event" readonly v-bind="attrs" v-on="on" clearable @click:clear="onClearName"></v-text-field>
                                    </template>
                                    <v-date-picker ref="birthdaypicker" locale="jp-ja" v-model="birthday" 
                                                    :day-format="birthday => new Date(birthday).getDate()" 
                                                    :max="date.toISOString().substr(0, 10)" 
                                                    min="1920-01-01" @change="birthdaysave">
                                    </v-date-picker>
                                </v-menu>

                                <v-text-field class="name" v-model="name" prepend-inner-icon="mdi-account" label="お名前" readonly></v-text-field>
                                <v-text-field class="mail" v-model="mail" :rules="mailRules" prepend-inner-icon="mdi-email" label="メールアドレス" required clearable></v-text-field>
                                
                                <!-- 退会予定日 -->
                                <v-menu ref="taikaidaymenu" v-model="taikaidaymenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="taikaiday" v-model="taikaiday" :rules="taikaidayRules" label="退会予定月" prepend-inner-icon="event" readonly v-bind="attrs" v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-date-picker ref="taikaidaypicker" locale="jp-ja" v-model="taikaiday" type="month" 
                                        :day-format="taikaiday => new Date(taikaiday).getDate()" 
                                        :max="taikaidateMax.toISOString().substr(0, 7)"
                                        :min="taikaidateMin.toISOString().substr(0, 7)" @change="taikaidaysave">
                                    </v-date-picker>
                                </v-menu>

                            </v-form>
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>
            <TaikaiCheckbox/>

            <v-row class="taikai-main__contents">
                <v-col cols="12" sm="8" md="4" class="taikai-main__contents-text">
                    <v-card flat color="transparent">
                        <v-card-text>
                            <v-form>
                               <v-textarea class="biko" v-model="biko" autocomplete="biko" label="備考"></v-textarea>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
                
            <v-row class="button">
                <v-col>
                    <v-btn large color="primary" min-width="300" @click="submit">
                        退会申込
                    </v-btn>
                </v-col>
            </v-row>

        </v-container>
    </section>
</template>

<script>
import TaikaiCheckbox from './TaikaiCheckbox.vue';
import moment from "moment";
export default {

    components: {
        TaikaiCheckbox,
    },
    
    data: () => ({

        date: moment.utc().add(9, 'hours'),
        
        taikaidateMax: null,
        taikaidateMin: null,

        memberid: '',
        memberidRules: [
        v => !!v || '必須項目です。',
        ],

        name: '',

        mail: '',
        mailRules: [
        v => !!v || '必須項目です。',
        v => /.+@.+\..+/.test(v) || '正しいメールアドレスを入力してください。',
        ],

        birthday: null,
        birthdaymenu: false,
        birthdayRules: [
        v => !!v || '必須項目です。',
        ],

        taikaiday: null,
        taikaidaymenu: false,
        taikaidayRules: [
        v => !!v || '必須項目です。',
        ],

        biko: '',

    }),

    // インスタンスが作成された後に同期的に呼ばれる
    created: function() {
        
        var day = this.date.date()  //現在日
        var minday = 1;
        var maxday = 10;
        var maxtaikaidate = new Date(this.date);
        var mintaikaidate = new Date(this.date);

        if (day >= minday && day <= maxday) {

            mintaikaidate.setMonth(mintaikaidate.getMonth() + 1)
            this.taikaidateMin = mintaikaidate
            maxtaikaidate = new Date(mintaikaidate)
            maxtaikaidate.setFullYear(maxtaikaidate.getFullYear() + 3);
            this.taikaidateMax = maxtaikaidate

        } else {

            mintaikaidate.setMonth(mintaikaidate.getMonth() + 2)
            this.taikaidateMin = mintaikaidate
            maxtaikaidate = new Date(mintaikaidate)
            maxtaikaidate.setFullYear(maxtaikaidate.getFullYear() + 3);
            this.taikaidateMax = maxtaikaidate
        
        }
    },



    watch: {
        birthdaymenu (val) {
            val && setTimeout(() => (
                // 年から選ぶようにする
                this.$refs.birthdaypicker.activePicker = 'YEAR',
                // 選び始めたら初期化
                this.birthdaypickerDate = null
            ))
        },

        taikaidaymenu (val) {
            val && setTimeout(() => (
                // 年から選ぶようにする
                this.$refs.taikaidaypicker.activePicker = 'YEAR',
                // 選び始めたら初期化
                this.taikaidaypickerDate = null
            ))
        },

    },

    methods: {
        birthdaysave (birthday) {
            this.$refs.birthdaymenu.save(birthday)
            // 再入力に備えて、入力が終わったら同期する
            this.birthdaypickerDate = birthday;

            this.getname();
        },
        
        taikaidaysave (taikaiday) {
            this.$refs.taikaidaymenu.save(taikaiday)
            // 再入力に備えて、入力が終わったら同期する
            this.taikaidaypickerDate = taikaiday;
        },
        
        getname() {
            
            this.name='';

            if (this.memberid == '' || this.memberid == null || this.birthday == null) {
                return false;
            }

            const postdata = {
                "会員ＣＤ": this.memberid,
                "誕生日": this.birthday
            };

            this.axios.post("https://renaiss-webapi-test.azurewebsites.net/api/RecessQuit/postName",postdata)

            .then(response => {
                
                if(response.data !== null){
                    this.name = response.data["memberName"];
                }else {
                    //ない処理
                    alert("存在しないアカウントです。")
                    return false;
                }
            })
            .catch(response => console.log(response))
        },

        submit() {
            if (this.$refs.form.validate()) {
                
                const putdata = {
                "会員ＣＤ": this.memberid,
                "退会日": this.taikaiday + "-01",
                };

                this.axios.put("https://renaiss-webapi-test.azurewebsites.net/api/RecessQuit/putDtQuit",putdata)

                .then(response => {
                    
                    alert("退会処理が完了しました。")
                    
            })
            .catch(response => console.log(response))
            
            } else {
                return false;
            }
        },

        onClearName() {
            this.name = ''
        },

    },
}
</script>


<style lang="scss" scoped>
.taikai-main__contents {
    justify-content: center;
    text-align: center;

    &-text {
        word-wrap: break-word;    
    }
}
.button {
    text-align: center;
    margin-top: 10px;
}
</style>